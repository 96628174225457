import { Link } from "react-router-dom";
import "./Footer.scss";
import { useViewportSize } from "../../utils/custom-hooks";
import { Row } from "../../components/Row/Row";
import { Column } from "../../components/Column/Column";

export const Footer = () => {
  return (
    <footer className="main-footer flex-column align-center padding-16">
      <>
        <Row className="row-1 flex-1 max-1200 margin-0 padding-lr-16">
          <Column width={0} className="flex-column">
            <div className="footer-left">
              <h3>
                BRAIN<span>BREW</span>
              </h3>
              <p>Get in touch</p>
              <a href="mailto:contact@brainbrewweb.com">
                contact@brainbrewweb.com
              </a>
            </div>
          </Column>
          <Column width={1}></Column>
          <Column width={1}>
            <Row className="footer-center flex-1">
              <Column>
                <ul className="flex-column justify-center">
                  <li className="main-footer-nav_item">
                    <Link className="transition" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="main-footer-nav_item">
                    <Link className="transition" to="services">
                      Services
                    </Link>
                  </li>
                  <li className="main-footer-nav_item">
                    <Link className="transition" to="contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Column>
              <Column>
                <ul>
                  <li className="main-footer-nav_item">
                    <Link className="transition" to="privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="main-footer-nav_item">
                    <Link className="transition" to="terms">
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row className="footer-bottom max-1200 justify-center align-center margin-0 padding-top-16">
          <p className="font-05">
            Copyright © 2024 Brain Brew Web Solutions LLC
          </p>
        </Row>
      </>
    </footer>
  );
};
