

import {useState} from "react";
import "./TheTemple.scss"

export const TheTemple = () => {

  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="page page-temple">
      <div class={`temple-header ${navOpen ? 'mw-open' : ''}`}>
        <nav class="temple-nav">
          <ul>
            <li>
              <a href="/the-temple">home</a>
            </li>
            <li>
              <a href="/the-temple">events</a>
            </li>
            <li>
              <a href="/the-temple">about</a>
            </li>
            <li>
              <a href="/the-temple">vip</a>
            </li>
          </ul>


          <button id="toggle-mw-nav" onClick={() => setNavOpen(!navOpen)}>
            {navOpen
              ? <svg id="icon-close" viewBox="0 0 32 32">
              <path d="M16 13.738l-11.314-11.314-2.262 2.262 11.314 11.314-11.314 11.314 2.262 2.262 11.314-11.314 11.314 11.314 2.262-2.262-11.314-11.314 11.314-11.314-2.262-2.262-11.314 11.314z"></path>
            </svg>
              : <svg id="icon-menu" viewBox="0 0 32 32">
              <path d="M0 4.8h32v3.2h-32v-3.2zM0 14.4h32v3.2h-32v-3.2zM0 24h32v3.2h-32v-3.2z"></path>
            </svg>
            }
              
            
          </button>
        </nav>
      </div>
      <div class="temple-logo">
        <img width="96" src="./img/clients/temple-logo-2.png" alt="Temple Logo" />
      </div>
      <div class="video-container">
        <video
          autoPlay
          playsInline
          muted
          loop
          src="./video/the-temple.mp4"
        ></video>
      </div>
    </div>
  );

};