import {Row} from "../../components/Row/Row"

export const Terms = () => {

  return (
    <div className="page page-privacy">
      <section className="flex-row justify-center">
        <Row className="flex-column max-800 m-bottom-64">
          <h2 className="text-center">Terms of Use</h2>
          <p><b>1. Acceptance of Terms</b><br />
          By accessing and using the website of Brain Brew Web Solutions LLC, you agree to be bound by these terms of use. If you do not agree, do not use the site.</p>

          <p><b>2. Use of the Site</b><br />
          The site is for informational purposes regarding our web design and development services. Any misuse or unauthorized access is prohibited.</p>

          <p><b>3. Intellectual Property</b><br />
          All content on this site is the property of Brain Brew Web Solutions LLC and is protected by intellectual property laws.</p>

          <p><b>4. Disclaimers</b><br />
          Our website is provided “as is”. We make no warranties regarding its accuracy or availability.</p>

          <p><b>5. Limitation of Liability</b><br />
          Brain Brew Web Solutions LLC is not liable for any damages arising from the use of this site.</p>

          <p><b>6. Changes to Terms</b><br />
          We reserve the right to modify these terms at any time. Continued use after changes constitutes acceptance of the new terms.</p>
        </Row>
      </section>
    </div>
  )
}