
import {Content} from './components/Content/Content'
import {Header} from './components/Header/Header'
import './App.scss'
import {Footer} from './components/Footer/Footer'
import {useLocation} from 'react-router-dom'
import {useEffect, useRef} from 'react'

function App() {

  const location = useLocation();
  const mainPageRef = useRef();

  // Scroll to top of page on route change
  useEffect(() => {
    mainPageRef.current.scrollTop = 0;
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    // Add class name 'dark' for dark theme
    <div className="app flex-column">
      <Header />
      <div ref={mainPageRef} className="main-page-container flex-1">
        {/* <Sidebar /> */}
        <Content />
        <Footer />
      </div>
    </div>
  );
}

export default App
