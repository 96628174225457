import {PropTypes} from "prop-types"

import "./Row.scss"

export const Row = ({
  children,
  className,
  gap = 16,
}) => {

  const styles = {

  }

  return (
    <div style={styles} className={`layout-row flex-row gap-${gap}${className ? ` ${className}` : ''}`}>

      {children}

    </div>
  )
}

Row.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.number,
};