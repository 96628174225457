import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export const ConfirmationModal = ({
  title,
  hasBtn = true,
  btnText,
  children,
  size = "dialog",
  isOpen,
  id,
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const modalId = id || "modal-" + Math.floor(Math.random() * 9999999);

  const modalComponent =
    open &&
    ReactDOM.createPortal(
      <div className={`cc-modal-container cc-modal-${size}`} id={modalId}>
        <button
          className="cc-modal-overlay"
          onClick={() => setOpen(!open)}
          aria-label="Close Modal"
        />
        <div className="cc-modal">
          {size !== "dialog" ? (
            <div className="cc-modal-header">
              <h3 className="cc-modal-title">{title}</h3>
              <button
                className="cc-modal-close"
                onClick={() => setOpen(!open)}
                aria-label="Close Modal"
              />
            </div>
          ) : (
            <button
              className="cc-modal-fixed-close"
              onClick={() => setOpen(!open)}
            ></button>
          )}

          <div className="cc-modal-body">
            {children}
            {hasBtn && (
              <button
                className="cc-open-modal cc-button shrink"
                onClick={() => setOpen(!open)}
                aria-haspopup="dialog"
                aria-controls={modalId}
              >
                {btnText}
              </button>
            )}
          </div>
        </div>
      </div>,
      document.body
    );

  return <>{modalComponent}</>;
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  hasBtn: PropTypes.bool,
  btnText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(["dialog", "small", "medium", "large", "full"]),
  isOpen: PropTypes.bool,
  id: PropTypes.string,
};
