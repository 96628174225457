import React, {useState, useRef, useEffect, useCallback} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import "./Popover.scss"

// ----------------------------------------------
// When passing a component as children,
// use forwardRef so this component can access it
// ----------------------------------------------
export function Popover({
  children,
  text,
  className,
  placement = 'right',
  style, // These are not passed directly to the style tag except for 'width' - see defaultStyle obj
  visible = false,
  showOnce = false,
  id,
  onClose = null,
}) {
  const [show, setShow] = useState(visible);
  const [expanded, setExpanded] = useState(false);
  const [coords, setCoords] = useState({top: 0, left: 0});

  const childRef = useRef(null);
  const tooltipRef = useRef();
  const placementRef = useRef(placement);

  const child = React.Children.only(children);
  const clonedChild = React.cloneElement(child, {ref: childRef});

  const defaultStyle = {
    backgroundColor: 'red',
    color: 'white',
    width: 'auto',
  };

  const propStyle = {...defaultStyle, ...style};

  useEffect(() => {
    placementRef.current = placement;
  }, [placement]);

  useEffect(() => {
    if (tooltipRef.current) {
      tooltipRef.current?.style.setProperty('--tooltip-color', propStyle.backgroundColor);
      tooltipRef.current?.style.setProperty('--tooltip-text-color', propStyle.color);
    }

    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  useEffect(() => {
    updatePosition();
  }, [children]);

  const onClickCloseHandler = useCallback(() => {
    if (onClose) {
      onClose();
    } else if (showOnce) {
      const hiddenTooltips = JSON.parse(localStorage.getItem('hidden-tooltips') || '[]');
      if (!hiddenTooltips.includes(id)) {
        hiddenTooltips.push(id);
        localStorage.setItem('hidden-tooltips', JSON.stringify(hiddenTooltips));
      }
    }

    setShow(false);
  }, []);

  const updatePosition = () => {

    if (childRef.current) {
      const rect = childRef.current.getBoundingClientRect();
      let top = 0;
      let left = 0;
      const offset = 16;

      switch (placementRef.current) {
        case 'top':
          top = rect.top + window.scrollY - offset;
          left = rect.left + rect.width / 2 + window.scrollX;
          break;

        case 'right':
          top = rect.top + rect.height / 2 + window.scrollY;
          left = rect.left + rect.width + window.scrollX + offset;
          break;

        case 'bottom':
          top = rect.top + rect.height + window.scrollY + offset;
          left = rect.left + rect.width / 2 + window.scrollX;
          break;

        case 'left':
          top = rect.top + rect.height / 2 + window.scrollY;
          left = rect.left + window.scrollX - offset;
          break;

        default:
          top = rect.top + window.scrollY - offset;
          left = rect.left + rect.width / 2 + window.scrollX;
          break;
      }

      setCoords({
        top,
        left,
      });
    }
  };

  const addedClasses =
    (placement ? ` cc-tooltip__${placement}` : '') +
    // (anchor ? ` cc-tooltip__anchor-${anchor}` : '') +
    (className ? ` cc-tooltip__${className}` : '') +
    (propStyle.shimmer && !expanded ? ' cc-tooltip__shimmer' : '') +
    (expanded ? ' cc-tooltip__expanded' : '');

  // If tooltip id is found in the hidden tooltips array, hide tooltip, render children only
  const hiddenTooltips = JSON.parse(localStorage.getItem('hidden-tooltips') || '[]');

  if (showOnce && hiddenTooltips.includes(id)) {
    return children;
  }

  return (
    <>
      {ReactDOM.createPortal(
        show && (
          <div
            ref={tooltipRef}
            onClick={onClickCloseHandler}
            className={`cc-tooltip__container${addedClasses}`}
            style={{
              top: coords.top,
              left: coords.left,
              width: propStyle.width,
            }}
          >
            <div className="cc-tooltip__content">
              <div className="cc-tooltip__text">
                <p>
                  {text}
                </p>
              </div>
            </div>
          </div>
        ),
        document.body
      )}
      {clonedChild}
    </>
  );
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
  style: PropTypes.object,
  visible: PropTypes.bool,
  showOnce: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
};
