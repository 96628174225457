import React, { useEffect, useRef, useState } from 'react';
import anime from 'animejs';
import './AnimatedText.scss';

export const AnimatedText = ({
  text,
  elementType,
  className,
  type,
  hovered,
  manual = false,
  delay = 100,
}) => {
  const textRef = useRef(null);
  const [classType, setClassType] = useState('ml2');

  useEffect(() => {
    if (type === 1) {
      setClassType('ml2');
    } else if (type === 2) {
      setClassType('ml12');
    } else if (type === 3) {
      setClassType('ml13');
    }
  }, [type]);

  useEffect(() => {
    
    if(hovered) {
      if (type === 1) {
        animation1();
      } else if (type === 2) {
        animation2();
      } else if (type === 3) {
        animation3();
      }
    }

  }, [hovered]);

  useEffect(() => {
    const textWrapper = textRef.current;
    textWrapper.innerHTML = text.split(/<br\s*\/?>/i)
      .map(line => `<span>${line.replace(/\S/g, "<span class='letter'>$&</span>")}</span>`)
      .join('<br />');

    if(manual) return;

    setTimeout(() => {
      if (type === 1) {
        animation1();
      } else if (type === 2) {
        animation2();
      } else if (type === 3) {
        animation3();
      }
    }, delay);
  }, [text, type, delay]);

  const animation1 = () => {
    anime.timeline({ loop: false })
      .add({
        targets: '.ml2 .letter',
        scale: [4, 1],
        opacity: [0, 1],
        translateZ: 0,
        easing: 'easeOutExpo',
        duration: 950,
        delay: (el, i) => 70 * i
      });
  }

  const animation2 = () => {
    anime.timeline({ loop: false })
      .add({
        targets: '.ml12 .letter',
        translateX: [40,0],
        translateZ: 0,
        opacity: [0,1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 3000 + 30 * i
      })
  }

  const animation3 = () => {
    anime.timeline({ loop: false })
    .add({
      targets: '.ml13 .letter',
      // opacity: [1,0],
      translateY: [0, 100], 
      easing: "easeOutExpo",
      duration: 1400,
      delay: (el, i) => 50 * (i + 1),
      endDelay: 500
    });
    
  }

  return React.createElement(
    elementType,
    { className: `animated-text ${classType} ${className}`, ref: textRef },
    null // Since the actual letters are added via innerHTML, no children here.
  );
};
