import {useEffect, useState} from "react";

// Returns 'mobile', 'tablet', or 'desktop' based on screen width
export const useViewportSize = () => {
  const [windowSize, setWindowSize] = useState('mobile');

  useEffect(() => {
    const handleResize = () => {

      let size = 'mobile'

      switch(true) {

        case window.innerWidth < 769:
          size = 'mobile'
          break;
        
        case window.innerWidth < 1025:
          size = 'tablet'
          break;

        case window.innerWidth >= 1025:
          size = 'desktop'
          break;

        default:
          break;
      }

      setWindowSize(size);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
