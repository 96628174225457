import {Route, Routes} from "react-router-dom"
import {Home} from "../../Pages/Home/Home"
import {Contact} from "../../Pages/Contact/Contact"
import {PrivacyPolicy} from "../../Pages/PrivacyPolicy/PrivacyPolicy"
import {Terms} from "../../Pages/Terms/Terms"
import {Services} from "../../Pages/Services/Services"
import {TheTemple} from "../../Pages/TheTemple/TheTemple"

import "./Content.scss"

export const Content = () => {

  return (
    <main className="main-content flex-1" aria-label="Main Content">
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="contact" element={<Contact />}></Route>
        <Route path="services" element={<Services />}></Route>
        <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="terms" element={<Terms />}></Route>
        <Route path="the-temple" element={<TheTemple />}></Route>
      </Routes>
    </main>
  )
}