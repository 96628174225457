import { Card } from "../../components/Card/Card";
import { Column } from "../../components/Column/Column";
import { Row } from "../../components/Row/Row";
import { AnimatedText } from "../../components/AnimatedText/AnimatedText";
import { Popover } from "../../components/Popover/Popover";
import { ContactForm } from "../../components/ContactForm/ContactForm";
import "./Home.scss";
import {useEffect, useRef, useState} from "react";

export const Home = () => {

  const [windowWidth, setWindowWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [introFinished, setIntroFinished] = useState(false);

  const siteIntroVideoRef = useRef();
  const siteIntroRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setTimeout(() => {
      setLoaded(true);

      setTimeout(() => {
        siteIntroVideoRef.current.classList.add('fly-out');
  
        setTimeout(() => {
          siteIntroRef.current.classList.add('fade-out');
          setIntroFinished(true);

          setTimeout(() => {
            siteIntroRef.current.classList.add('hide');
          },500);
        },250);
      },2500);
    },500);

    

    
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // const isMobile = window.innerWidth < 769;

  return (
    <div className="page page-home">
      <div className="full-page site-intro" ref={siteIntroRef}>
        {loaded &&
          <video
            ref={siteIntroVideoRef}
            className="site-intro-video"
            autoPlay
            playsInline
            muted
            src="./img/logos/logo-animation.mp4"
          ></video>
        }
      </div>
      <div className="above-the-fold flex-column align-center justify-center gap-32">
        {introFinished &&
          <>
            <video
              className="homepage-video"
              autoPlay
              playsInline
              muted
              loop
              src="./video/homepage-video.hevc.mp4"
            ></video>
            <AnimatedText
              type={1}
              className="text-white text-center"
              text={`The future<br />of your business<br />starts now.`}
              elementType="h2"
              delay={1000}
            />
            <AnimatedText
              type={2}
              className="text-white text-center"
              text={`BrainBrew`}
              elementType="h2"
              delay={1500}
            />
          </>
        }
      </div>
      <section id="who-we-are">
        <Row className="flex-column padding-32 padding-tablet-16 gap-tablet-0 align-center text-center">
          <h2 className="text-center full-width padding-tablet-16">Who We Are</h2>
          {/* <div className="bios flex-row justify-between m-bottom-32">
            <img className="bio-pic" width="200" src="./img/bios/micah-dilan.jpg" />
            <img className="bio-pic" width="200" src="./img/bios/brett-baylie.jpg" />
          </div> */}
          <p className="max-600">
            Introducing Brain Brew Web Solutions, a dynamic team of web design
            and developer ninjas with a passion for cutting edge technology and
            sleek design. Our mastery of engineering and deep understanding of
            graphic design, modern aesthetic and web 3.0 look and feel sets us
            apart from the pack. We're not just a web design company; we're your
            partners in elevating your online presence to new heights. Join us
            on this creative journey, and let's turn your vision into digital
            reality.
          </p>
        </Row>
      </section>
      <section id="what-we-do" className="section-services full-width padding-32 padding-tablet-16 bkg-grey6 flex-column align-center">
        <h2 className="text-center full-width padding-32 padding-tablet-16">What We Do</h2>
        <Row className="max-1200">
          <Column width={1}>
            <Card title="Web Design and Development" color="black" description="Building a responsive, accessibility-compliant, user-friendly website to meet your company's needs.">
              <img width="96" src="./img/graphics/web-design.png" alt="Web Design and Development" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Company Branding" color="yellow" description="Crafting a unique identity for your brand, including logo design, company graphics, color palettes and more.">
              <img width="96" src="./img/graphics/branding.png" alt="Company Branding" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Digital Marketing" color="blue" description="Running targeted advertising campaigns across various online platforms to increase brand visibility and drive customer engagement.">
              <img width="96" src="./img/graphics/marketing.png" alt="Digital Marketing" />
            </Card>
          </Column>
        </Row>
        <Row className="max-1200 flex-column-reverse-mobile">
          <Column width={1}>
            <Card title="E-commerce" color="blue" description="Boosting online sales with high-performance e-commerce platforms, designed for success and tailored to your brand.">
              <img width="96" src="./img/graphics/ecommerce.png" alt="E-commerce" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Videos and Animation" color="peach" description="Elevating your brand with high def video ads, video backgrounds, and sleek web animations.">
              <img width="96" src="./img/graphics/video.png" alt="Videos and Animation" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Search Engine Optimization" color="yellow" description="Boosting your website's search engine visibility with targeted SEO strategies, increasing organic traffic and enhancing online presence.">
              <img width="96" src="./img/graphics/seo.png" alt="Search Engine Optimization" />
            </Card>
          </Column>
        </Row>
        <Row className="max-1200">
          <Column width={1}>
            <Card title="Copy Writing" color="yellow" description="Composing engaging, high-quality content tailored to your brand's voice.">
              <img width="96" src="./img/graphics/copywriting.png" alt="Copy Writing" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Graphic Design" color="black" description="Creating stunning, modern graphics personalized to your brand.">
              <img width="96" src="./img/graphics/graphic-design.png" alt="Graphic Design" />
            </Card>
          </Column>
          <Column width={1}>
            <Card title="Security" color="peach" description="Protecting your company from cyber attacks, data loss, and malware.">
              <img width="96" src="./img/graphics/security.png" alt="Securit" />
            </Card>
          </Column>
        </Row>
      </section>
      <section id="our-latest-work-1" className="our-latest-work full-width padding-32 flex-column align-center">
        <h2 className="text-center full-width padding-32">Our Latest Work</h2>
        <Row className="max-1000 m-bottom-64 gap-32 flex-row-reverse">
          {windowWidth < 990
            ? <Column width={10}>
              <p><b>Client:</b> Ethan Watts Photo</p>
              <img className="full-width m-bottom-16" src="./img/clients/ethan-watts.jpg" alt="ethan watts photo" />
              <p><b>The Challenge:</b> Help a seasoned wedding and portraits photographer branch out into other more creative photography pursuits where he can really expand his artistic vision. This is something of a second business upstart and the accompanying website required one thing in spades: accentuate the images. There is a lot going on in the UI and animations but everything is meant to draw your focus to the photos, make navigating and exploring as easy as possible and generate client leads for creative shoots.</p>
            </Column>
            : <>
            <Column width={3}>
              <img className="full-width" src="./img/clients/ethan-watts.jpg" alt="ethan watts photo" />
            </Column>
            <Column width={10}>
              <p><b>Client:</b> Ethan Watts Photo</p>
              <p><b>The Challenge:</b> Help a seasoned wedding and portraits photographer branch out into other more creative photography pursuits where he can really expand his artistic vision. This is something of a second business upstart and the accompanying website required one thing in spades: accentuate the images. There is a lot going on in the UI and animations but everything is meant to draw your focus to the photos, make navigating and exploring as easy as possible and generate client leads for creative shoots.</p>
            </Column>
          </>
        }
        </Row>
      </section>
      <div className="laptop-scene full-width">
        {windowWidth < 769
          ? <img className="mobile-scene" height="700" src="./img/common/homepage-iphone-scene.jpg" alt="iphone screen with client website example" />
          : <img className="desktop-scene" height="700" src="./img/common/homepage-laptop-scene.jpg" alt="laptop screen with client website example" />
        }
        {loaded &&
          <div className="laptop-screen-frame">
            <iframe
              title="laptop-screen"
              className="laptop-screen"
              src="https://ethanwattsphoto.com/"
              width="100%"
              height="600px"
            >
              This browser does not support iframes.
            </iframe>
          </div>
        }
      </div>


      <section id="our-latest-work-2" className="our-latest-work full-width padding-32 flex-column align-center">
        <br /><br /><br />
        <Row className="max-1000 m-bottom-64 gap-32">
          {windowWidth < 990
            ? <Column width={10}>
              <p><b>Client:</b> 2024 Utah State Senate candidate Oran Stainbrook</p>
              <img className="full-width m-bottom-16" src="./img/clients/oran-stainbrook.jpg" alt="oran stainbrook for utah state senate" />
              <p><b>The Challenge:</b> Create a compelling online presence for an asiring politician that is modern but conservative, represents the values of an independent candidate who loves his district of beautiful national parks and communities with a mixed set of political persuasions. The goal was to not alienate anyone while creating a modern look and feel. The site is brought to life with web 3.0 animations and beautiful graphics generated in-house. We're still working with Oran to increase his online presence, draw attention to his platform, and encourage people to get out and vote. He is running for Utah State Senate in November and he's a candidate we truly believe in so we're doing everything we can to help.</p>
            </Column>
            : <>
            <Column width={3}>
              <img className="full-width" src="./img/clients/oran-stainbrook.jpg" alt="oran stainbrook for utah state senate" />
            </Column>
            <Column width={10}>
              <p><b>Client:</b> 2024 Utah State Senate candidate Oran Stainbrook</p>
              <p><b>The Challenge:</b> Create a compelling online presence for an asiring politician that is modern but conservative, represents the values of an independent candidate who loves his district of beautiful national parks and communities with a mixed set of political persuasions. The goal was to not alienate anyone while creating a modern look and feel. The site is brought to life with web 3.0 animations and beautiful graphics generated in-house. We're still working with Oran to increase his online presence, draw attention to his platform, and encourage people to get out and vote. He is running for Utah State Senate in November and he's a candidate we truly believe in so we're doing everything we can to help.</p>
            </Column>
          </>
        }
        </Row>
      </section>
      <div className="laptop-scene full-width">
        {windowWidth < 769
          ? <img className="mobile-scene" height="700" src="./img/common/homepage-iphone-scene.jpg" alt="iphone screen with client website example" />
          : <img className="desktop-scene" height="700" src="./img/common/homepage-laptop-scene.jpg" alt="laptop screen with client website example" />
        }
        {loaded &&
          <Popover
            placement={windowWidth < 990 ? 'bottom' : 'right'}
            text="Try scrolling! This is live 🙂"
            visible={true}
            expandedImgSrc="/assets/images/full_page_background.webp"
            dataQaId="premium-features-warning-close"
            style={{backgroundColor: '#e03300', color: 'white', width: windowWidth < 768 ? '270px' : 'auto'}}
            showOnce
            id="tooltip-id-1"
            onClose={() =>
              localStorage.setItem('tooltip-id-to-hide', 'premium-features-warning')
            }
          >
            <div className="laptop-screen-frame">
              <iframe
                title="laptop-screen"
                className="laptop-screen"
                src="https://oranforutah.com/"
                width="100%"
                height="600px"
              >
                This browser does not support iframes.
              </iframe>
            </div>
          </Popover>
        }
      </div>

      <section id="our-latest-work-3" className="our-latest-work full-width padding-32 flex-column align-center">
        <br /><br /><br />
        <Row className="max-1000 m-bottom-64 gap-32">
          {windowWidth < 990
            ? <Column width={10}>
              <p><b>Client:</b> Castle Productions</p>
              <img className="full-width m-bottom-16" src="./img/clients/iseethedemon.jpg" alt="i see the demon film poster" />
              <p><b>The Challenge:</b> Create a captivating landing page for an upcoming horror film from Castle Productions: I See The Demon. The main focus of this site is to give information to investors, film festival reps, and fans; drive traffic to the social media accounts; and allow people to sign up for a newsletter on the latest updates on the film. The site also needed to have the same vibe and feeling as the film and showcase some of the stills from the film.</p>
            </Column>
            : <>
            <Column width={10}>
              <p><b>Client:</b> Castle Productions</p>
              <p><b>The Challenge:</b> Create a captivating landing page for an upcoming horror film from Castle Productions: I See The Demon. The main focus of this site is to give information to investors, film festival reps, and fans; drive traffic to the social media accounts; and allow people to sign up for a newsletter on the latest updates on the film. The site also needed to have the same vibe and feeling as the film and showcase some of the stills from the film.</p>
            </Column>
            <Column width={3}>
              <img className="full-width" src="./img/clients/iseethedemon.jpg" alt="i see the demon film poster" />
            </Column>
          </>
        }
        </Row>
      </section>
      <div className="laptop-scene full-width">
        {windowWidth < 769
          ? <img className="mobile-scene" height="700" src="./img/common/homepage-iphone-scene.jpg" alt="iphone screen with client website example" />
          : <img className="desktop-scene" height="700" src="./img/common/homepage-laptop-scene.jpg" alt="laptop screen with client website example" />
        }
        {loaded &&
          <Popover
            placement={windowWidth < 990 ? 'bottom' : 'right'}
            text="Try scrolling! This is live 🙂"
            visible={true}
            expandedImgSrc="/assets/images/full_page_background.webp"
            dataQaId="premium-features-warning-close"
            style={{backgroundColor: '#e03300', color: 'white', width: windowWidth < 768 ? '270px' : 'auto'}}
            showOnce
            id="tooltip-id-1"
            onClose={() =>
              localStorage.setItem('tooltip-id-to-hide', 'premium-features-warning')
            }
          >
            <div className="laptop-screen-frame">
              <iframe
                title="laptop-screen"
                className="laptop-screen"
                src="https://iseethedemon.com/"
                width="100%"
                height="600px"
              >
                This browser does not support iframes.
              </iframe>
            </div>
          </Popover>
        }
      </div>
      
      <section id="contact" className="home-contact-form flex-row gap-24 justify-center max-1000 m-bottom-64">
        <ContactForm />
      </section>
    </div>
  );
};
