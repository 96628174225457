import React, {useEffect, useState} from 'react';
import './Card.scss'; 

export const Card = ({
  children,
  description,
  className,
  color,
  title,
  bkgColor = '#FFFFFF'
}) => {
  const [animating, setAnimating] = useState(false);
  const [hovered, setHovered] = useState(false);

  let borderColor = '#fff';

  switch (color) {
    case 'black':
      borderColor = '#495058';
      break;
    case 'yellow':
      borderColor = '#ffce67';
      break;
    case 'blue':
      borderColor = '#90def5';
      break;
    case 'peach':
      borderColor = '#fd8469';
      break;
    default:
      break;
  }

  const styles = {
    backgroundColor: bkgColor,
    borderTop: `3px solid ${borderColor}`,
  };
  
  useEffect(() => {
    if (!animating) {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
      }, 1500); // Ensure this matches the longest animation duration
    }
  }, [hovered]); // Depend on hovered to trigger, but use animating to prevent overlap

  const handleMouseEnter = () => {
    if (!animating) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (animating) {
      // Delay mouse leave action until animation completes
      setTimeout(() => {
        setHovered(false);
      }, 1500); // Match this with your animation duration
    } else {
      setHovered(false);
    }
  };
  
  return (
    <div
      className={`cc-card${className ? ` ${className}` : ''}${hovered ? ' flip' : ' unflip'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={styles}
    >
      <div className="h4-frame-hover"><h4>{title}</h4></div>
      {children}
      <div className="h4-frame"><h4>{title}</h4></div>
      <div className="description">
        <p>{description}</p>
      </div>
    </div>
  );
};
