import {PropTypes} from "prop-types"

import "./Column.scss"

export const Column = ({
  children,
  width = 1,
  className,
  bkg,
}) => {

  const styles = {
    flex: width,
    background: bkg,
  }

  return (
    <div style={styles} className={`layout-column${className ? ` ${className}` : ''}`}>

      {children}

    </div>
  )
}

Column.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
};