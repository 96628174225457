import {Card} from "../../components/Card/Card"
import {Column} from "../../components/Column/Column"
import {Row} from "../../components/Row/Row"
import "./Services.scss";

export const Services = () => {

  return (
    <div className="page page-services">
      <Column className="padding-top-32">
        <h2 className="text-center">Our Services</h2>
        <section className="flex-column align-center justify-center">
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/web-design.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Web Design and Development</h3>
              <p>Our web design company excels in creating custom websites that are responsive, accessible, and user-friendly. We adapt to your unique needs using the latest technologies to ensure smooth performance across all devices. Our focus on accessibility and intuitive design enhances user engagement and supports your brand's inclusivity. Partner with us to develop a standout digital presence that drives growth and reflects your brand's values efficiently.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/branding.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Company Branding</h3>
              <p>Our team specializes in developing a distinctive brand identity that resonates with your target audience. From logo design to company graphics and color palettes, we provide a comprehensive suite of branding services. We ensure your brand stands out with a cohesive visual identity that communicates your company's values and appeals to your customers, fostering recognition and loyalty. Let us elevate your brand with a unique and memorable identity.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/marketing.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Digital Marketing</h3>
              <p>Our digital marketing expertise focuses on executing strategic advertising campaigns across multiple online platforms to boost your brand's visibility and engage customers effectively. We leverage the power of digital channels to reach your audience precisely where they spend their time, enhancing brand awareness and fostering engagement. Let us help you navigate the digital landscape to achieve tangible results and grow your business.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/seo.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Search Engine Optimization</h3>
              <p>Our SEO services focus on enhancing your website's visibility in search engine results through targeted strategies, aiming to increase organic traffic and bolster your online presence. By optimizing content, improving site structure, and leveraging key analytics, we ensure your brand ranks higher, reaches your target audience more effectively, and achieves sustainable growth in the digital landscape. Let us drive your SEO success and elevate your brand's online footprint.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/video.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Videos and Animation</h3>
              <p>We elevate brands by crafting high-definition video ads, immersive video backgrounds, and sleek web animations that capture attention and convey messages dynamically. Our team combines creativity with technical expertise to create visual content that stands out, enhances user experience, and strengthens your online presence. Let us bring your brand to life in the digital space with compelling videos and animations.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/ecommerce.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">E-commerce</h3>
              <p>Our approach to e-commerce involves creating high-performance platforms tailored to your brand, aimed at boosting online sales and paving the path to success. We focus on developing seamless shopping experiences that reflect your brand identity and meet your business goals, ensuring your online store stands out in the competitive digital marketplace. Let us help you capture and grow your online audience with a custom e-commerce solution.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/copywriting.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Copy Writing</h3>
              <p>Our copywriting services are centered on crafting engaging, high-quality content that resonates with your brand's unique voice. We specialize in creating compelling narratives that connect with your audience, reflect your brand identity, and communicate your message effectively. Let us help you tell your story in a way that captivates and converts, ensuring your brand stands out in the crowded digital marketplace.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/graphic-design.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Graphic Design</h3>
              <p>Our graphic design team excels in creating stunning, modern visuals that are fully personalized to your brand's identity. With a keen eye for aesthetics and a deep understanding of brand communication, we produce graphics that not only look beautiful but also resonate with your target audience. Let us help you make a lasting impression with visuals that differentiate your brand and engage your customers.</p>
            </Column>
          </Row>
          <Row className="flex-row max-800 m-bottom-64">
            <Column width={1}>
              <img width="96" src="./img/graphics/security.png" />
            </Column>
            <Column width={6}>
              <h3 className="m-bottom-16">Security</h3>
              <p>Our security offerings focus on protecting your business from cyber threats, safeguarding against data loss, and fighting off malware. Utilizing essential security practices, we aim to keep your digital assets and sensitive data safe. We're here to strengthen your security measures, offering you reassurance and ensuring your business remains secure amidst the constantly changing digital environment.</p>
            </Column>
          </Row>
        </section>
      </Column>
    </div>
  )
}

