import { PropTypes } from "prop-types";

import "./Button.scss";
import Spinner from "../Spinner/Spinner";

/** This is a simple example of how the component works **/
export const Example = () => {
  return (
    <Button
      className
      size
      onClick={() => {}}
      fullWidth="false"
      align
      isLink
      href=""
      type="primary"
    >
      This is a button
    </Button>
  );
};
/** This is a simple example of how the component works **/

export const Button = ({
  children,
  className,
  size = "medium",
  onClick = () => {},
  fullWidth,
  isLink = false,
  href = null,
  type = "primary",
  loading = false,
  isSubmit,
}) => {
  const addedClasses =
    ` ${className ? ` ${className}` : ""}` +
    `${size ? ` cc-button-${size}` : ""}` +
    `${type ? ` cc-button-${type}` : ""}` +
    `${fullWidth ? " cc-button-full-width" : ""}`;

  const Element = isLink ? "a" : "button";

  return (
    <>
      <Element
        tabIndex="0"
        className={"cc-button" + addedClasses}
        onClick={onClick}
        href={href}
        disabled={loading}
        type={isSubmit ? "submit" : "button"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && isLink) {
            onClick();
          }
        }}
      >
        {loading ? <Spinner /> : children}
      </Element>
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "transparent"]),
  isSubmit: PropTypes.bool,
};
