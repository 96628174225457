import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import "./Input.scss"

/** This is a simple example of how the component works **/
const Example = ({}) => {

  return (
    <Input
      label="Text Element"
      column={3}
      width="300px"
      type="text"
      onChange={() => {}}
    />
  )
}
/** This is a simple example of how the component works **/


export const Input = ({
  label,
  column,
  width = '100%',
  onChange,
  value,
  type,
  required = false,
  showErrorMsg = false,
  id,
  placeholder = ''
}) => {

  const [error, setError] = useState('');

  const handleChange = (e) => {

    let filteredValue = e.target.value;

    if (e.nativeEvent.inputType !== 'deleteContentBackward') {

      // Input is empty, remove error state
      if(filteredValue === '') {
        setError('');
        return;
      }
  
      switch(type) {
  
        case 'tel':
  
          // Format telephone number: +12 (888) 345-6789
          filteredValue = filteredValue.replace(/\D/g, '');
  
          if(filteredValue.length > 12) return;
  
          if (filteredValue.length > 1) {
            let cc = 0;
            if(filteredValue.length === 12) cc = 1;
            let formattedNumber = `+${filteredValue.substring(0, 1+cc)} (${filteredValue.substring(1+cc, 4+cc)}) ${filteredValue.substring(4+cc,7+cc)}`;
            if(filteredValue.length > 7) formattedNumber += `-${filteredValue.substring(7+cc)}`;
            filteredValue = formattedNumber;
          } 
          break;
  
        case 'url':
  
          // Validate URL
          const pattern = new RegExp('\\b((?:[a-z][\\w-]+:(?:/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:\'".,<>?«»“”‘’]))','i');
          const hasInvalidFirstChar = !/^[a-z]/i.test(filteredValue);
          const hasInvalidLastChar = filteredValue.slice(-1) === '.'
          const hasLessThanTwoDots = filteredValue.split('.').length - 1 < 2;
          const hasInvalidRegex = !pattern.test(filteredValue);
  
          if(hasInvalidFirstChar || hasInvalidLastChar || hasLessThanTwoDots || hasInvalidRegex) {
            setError('Error: Invalid URL');
          } else {
            setError('');
          }
          break;
  
        default:
          break;
      }
    }


    onChange(filteredValue);
  };

  const labelStyles = {
    width: column ? `calc((100% / 12) * ${column})` : width,
    ...(error && {color: 'red'}),
  }
  
  const inputStyles = {
    ...(error && {borderColor: 'red'}),
  }

  const inputId = id || 'input-' + Math.floor(Math.random() * 999999);
  const errorId = `${inputId}-error`;

  return (
    <label htmlFor={inputId} className="cc-input" style={labelStyles}>
      <span className="cc-input-label">{label}</span>
      <input
        type={!['url', 'email'].includes(type) ? type : null}
        className="cc-input-element"
        value={value}
        onChange={handleChange}
        style={inputStyles}
        required={required}
        aria-required={required}
        aria-invalid={!!error}
        aria-describedby={showErrorMsg ? error : null}
        id={id || inputId}
        placeholder={placeholder}
      />
      {/* {showErrorMsg && (
        <span id={errorId} className="cc-input-error" role="alert">
          {error}
        </span>
      )} */}
    </label>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  column: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  width: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'color',
    'date', 
    'email',
    'file',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url'
  ]),
  placeholder: PropTypes.string
}