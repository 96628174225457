import { Button } from "../../components/Button/Button";
import { Column } from "../../components/Column/Column";
import { Input } from "../../components/Input/Input";
import { Row } from "../../components/Row/Row";
import "./ContactForm.scss";
import { useState } from "react";
import Axios from "axios";
import { ConfirmationModal } from "./ConfirmationModal";
import "./ConfirmationModal.scss";
import { Icon } from "../../components/Icon/Icon";
import { Dropdown } from "../../components/Dropdown/Dropdown";

const BUDGET_DROPDOWN_OPTIONS = [
  { value: "Under $2000", label: "Under $2000" },
  { value: "$2000 - $5000", label: "$2000 - $5000" },
  { value: "$5000 - $10000", label: "$5000 - $10000" },
  { value: "Above $10000", label: "Above $10000" },
];

export const ContactForm = ({ className }) => {
  const [loading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [entityName, setEntityName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOnSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const subject = `Contact Form - ${firstName} ${lastName} - ${entityName}`;
    const content = `email: ${email}   phone: ${phone}   message: ${message}   budget: ${budget}`;

    try {
      await Axios.post("https://brainbrewweb.com/send-message.php", {
        subject,
        content,
      });

      setSubmitStatus("success");
      setLoading(false);
      setModalOpen(true);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setEntityName("");
      setMessage("");
      setBudget(0);
    } catch (error) {
      console.error("Error sending email:", error);
      setSubmitStatus("error");
      setLoading(false);
      setModalOpen(true);
    }
  };

  return (
    <Row className="contact-section position-relative max-1000 padding-top-32">
      <Column width={3} className="contact-instructions radius-8">
        <div className="contact-text justify-between padding-32 flex-column gap-16 ">
          <h2 className="m-bottom-32">Let's start the conversation</h2>
          <ul className="flex-column gap-32">
            <li className="flex-row gap-16">
              <Icon type="one" ratio={1.5}></Icon>
              <p>Fill out this form so we know what you are looking for</p>
            </li>
            <li className="flex-row gap-16">
              <Icon type="two" ratio={1.5}></Icon>
              <p>A team member will reach out within 24hrs</p>
            </li>
            <li className="flex-row gap-16">
              <Icon type="three" ratio={1.5}></Icon>
              <p>We will give you an estimate of the full project</p>
            </li>
          </ul>
        </div>
      </Column>
      <Column width={4} className="contact-form radius-16">
        <div className={`page page-contact ${className ? className : ""}`}>
          <section className="padding-top-32 max-1000">
            <form onSubmit={handleOnSubmit}>
              <Row>
                <Column width={3}>
                  <Input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                  />
                </Column>
                <Column width={3}>
                  <Input
                    type="text"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={(value) => setLastName(value)}
                  />
                </Column>
              </Row>
              <Row>
                <Column width={1}>
                  <Input
                    type="email"
                    value={email}
                    placeholder="Email *"
                    required
                    onChange={(value) => setEmail(value)}
                  />
                </Column>
                <Column width={1}>
                  <Input
                    type="tel"
                    value={phone}
                    placeholder="Phone Number"
                    onChange={(value) => setPhone(value)}
                  />
                </Column>
              </Row>
              <Row>
                <Column width={3}>
                  <Input
                    type="text"
                    value={entityName}
                    placeholder="Company Name"
                    onChange={(value) => setEntityName(value)}
                  />
                </Column>
              </Row>
              <Row>
                <Column width={3}>
                  <Dropdown
                    options={BUDGET_DROPDOWN_OPTIONS}
                    placeholder="Your budget"
                    value={budget}
                    onSelect={(option) => setBudget(option)}
                  />
                </Column>
              </Row>
              <Row>
                <Column width={3}>
                  <label className="cc-input-label flex-column gap-8 padding-top-8 font-1">
                    <textarea
                      placeholder="Tell us about what you're looking for *"
                      required
                      rows="10"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </label>
                </Column>
              </Row>
              <Row className="flex-column align-center justify-center full-width padding-top-16">
                <Button
                  className="full-width"
                  type="primary"
                  loading={loading}
                  isSubmit
                >
                  Submit
                </Button>
              </Row>
              {modalOpen && (
                <ConfirmationModal
                  isOpen={modalOpen}
                  hasBtn={true}
                  size="dialog"
                  btnText="OK"
                >
                  <div className="submit-message padding-16 font-05">
                    {submitStatus === "success" ? (
                      <>
                        <p>Thank you for contacting us.</p>
                        <p>We will be in touch soon!</p>
                      </>
                    ) : (
                      <div className="font-05">
                        <p>Oops, there's an error sending your message. </p>
                        <p>
                          Please try emailing us directly at
                          contact@brainbrewweb.com
                        </p>
                      </div>
                    )}
                  </div>
                </ConfirmationModal>
              )}
            </form>
          </section>
        </div>
      </Column>
    </Row>
  );
};
