
import {Link} from "react-router-dom"
import "./Header.scss"
import {useViewportSize} from "../../utils/custom-hooks";
import {Icon} from "../Icon/Icon";
import {Row} from "../Row/Row";
import {useState} from "react";


export const Header = () => {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const windowSize = useViewportSize() || 'desktop';

  return (
    <header id="main-header" className={`main-header justify-center flex-row${windowSize !== 'desktop' ? ' mobile-header' : ''}`}>
      <Row className="justify-between max-1200 padding-lr-16 margin-0">
        <div className="header-left">
          <Link className="title-logo transition flex-row align-center justify-center gap-16" to="/">
            <img width="80px" src="./img/logos/logo-original-halfsize-transparent.png" />
            <h1 className="flex-row justify-center">Brain <span>Brew</span><sub>Web Solutions</sub></h1>
          </Link>
        </div>
        {windowSize === 'mobile'
          ? <>
            <button className="icon-button" onClick={() => setMobileNavOpen(true)}>
              <Icon type="menu" />
            </button>
            <div className={`mobile-nav${mobileNavOpen ? ' mobile-nav-open' : ''} transition padding-16`}>
              <div className="mobile-nav-header flex-row">
                <h2 className="flex-1">Menu</h2>
                <button className="icon-button close-icon transparent" onClick={() => setMobileNavOpen(false)}>
                  <Icon type="close" ratio={0.75} />
                </button>
              </div>
              <div className="mobile-nav-content">
                <nav className="flex-column flex-1">
                  <ul className="flex-column gap-24">
                    <li className="main-mobile-nav_item"><Link onClick={() => setMobileNavOpen(false)} className="transition" to="/">Home</Link></li>
                    <li className="main-mobile-nav_item"><Link onClick={() => setMobileNavOpen(false)} className="transition" to="services">Services</Link></li>
                    <li className="main-mobile-nav_item"><Link onClick={() => setMobileNavOpen(false)} className="transition" to="contact">Contact</Link></li>         
                  </ul>
                </nav>
              </div>
            </div>
          </>
          : <>
            <div className="header-center flex-row align-center justify-center">
              <nav className="flex-column flex-1 align-center">
                {/* <h2>Menu</h2> */}
                <ul className="flex-row gap-24">
                  <li className="main-header-nav_item hover-underline"><Link className="transition" to="/">Home</Link></li>
                  <li className="main-header-nav_item hover-underline"><Link className="transition" to="services">Services</Link></li>
                  <li className="main-header-nav_item hover-underline"><Link className="transition" to="contact">Contact</Link></li>
                </ul>
              </nav>
            </div>
            {/* <div className="header-right flex-row align-center gap-8">
              <span>Username</span>
              <div className="profile-photo radius-50">
                <img width="40px" src="./img/profile.png" alt="Profile" />
              </div>
            </div> */}
          </>
        }
      </Row>
      
    </header>
  )
}
