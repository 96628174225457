import {Row} from "../../components/Row/Row"

export const PrivacyPolicy = () => {

  return (
    <div className="page page-privacy">
      <section className="flex-row justify-center">
        <Row className="flex-column max-800 m-bottom-64">
          <h2 className="text-center">Privacy Policy</h2>
          <p><b>1. Collection of Information</b><br />
          We collect information through our website forms, which is used solely for responding to inquiries. This may include name, email address, phone number, and message content.</p>

          <p><b>2. Use of Information</b><br />
          The information collected is used to communicate with you about your inquiry. We do not share or sell this information.</p>

          <p><b>3. Data Security</b><br />
          We strive to protect the security of your information during transmission by using appropriate measures.</p>

          <p><b>4. Changes to Our Privacy Policy</b><br />
          We may update this policy from time to time. We will notify you of any significant changes.</p>

          <p><b>5. Contact Us</b><br />
          If you have questions about this policy, please contact us at [your contact info].</p>
        </Row>
      </section>
    </div>
  )
}