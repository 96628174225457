import React, { useState } from "react";
import Select from "react-select";

const customStyles = {
  option: (defaultStyles) => ({
    ...defaultStyles,
    fontSize: "1rem/1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1rem/1.25rem",
    border: "1px solid #d7dbe2",
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: "#888",
  }),
};

export const Dropdown = ({ options, onSelect, placeholder }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelect(selectedOption.value);
  };

  return (
    <Select
      className="dropdown-select margin-top-8"
      value={selectedOption}
      onChange={handleSelect}
      options={options}
      placeholder={placeholder}
      placeholderTextColor={"#f00"}
      styles={customStyles}
    >
      <option value={placeholder}>{placeholder}</option>
      {options.map((option) => (
        <option key={option} value={option} style={customStyles}>
          {option}
        </option>
      ))}
    </Select>
  );
};
